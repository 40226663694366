import React from "react";
import { Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    minWidth: 275,
    marginTop: 50,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 24,
  },
  pos: {
    marginBottom: 12,
  },
});

class CustomConfirmSignUp extends React.Component {

  render() {
    const { classes } = this.props;
    if (this.props.authState === "confirmSignUp") {
      return (
        <Card className={classes.root}>
          <CardContent>
            <h3 className={classes.title}>
              Thank you for registering
            </h3>
            <p>
              Please check your email for your sign up confirmation link
            </p>
          </CardContent>
        </Card>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(CustomConfirmSignUp)

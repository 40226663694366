import React from "react";

import { Redirect } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import DOMPurify from "dompurify";
import { FormGroup, Button, Box, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { TextField } from "@material-ui/core";

const alertSeverity = {
  error: "error",
  info: "info",
  success: "success",
  warning: "warning"
};

class UserAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      name: "",
      family_name: "",
      phone_number: "",
      address: "",
      custom_address_line_2: "",
      custom_city: "",
      custom_state: "",
      custom_zipcode: "",
      editStatus: { show: false, type: "", title: "", message: "" }
    };
    Hub.listen("auth", this.setInitialState);
  }

  componentWillUnmount() {
    Hub.remove("auth", this.setInitialState);
  }

  setInitialState = data => {
    const user = data.payload.data;
    if (data.payload.event === "signIn") {
      this.setState({
        email: user.attributes.email || "",
        name: user.attributes.name || "",
        family_name: user.attributes.family_name || "",
        phone_number: user.attributes.phone_number || "",
        address: user.attributes["address"] || "",
        custom_address_line_2: user.attributes["custom:address_line_2"] || "",
        custom_city: user.attributes["custom:city"] || "",
        custom_state: user.attributes["custom:state"] || "",
        custom_zipcode: user.attributes["custom:zipcode"] || ""
      });
    }
  };

  // ALERT METHODS
  showAlert = (e, type, customMessage) => {
    this.setState({
      editStatus: {
        show: true,
        type: type || alertSeverity.info,
        title: "Alert",
        message: customMessage || e.message || ""
      }
    });
  };

  hideAlert = () => {
    this.setState({
      editStatus: {
        show: false,
        type: alertSeverity.info,
        title: "",
        message: ""
      }
    });
  };

  // PASSWORD METHODS
  updatePassword = async e => {
    e.preventDefault();

    const current_password = document.getElementById("current_password").value;
    const new_password = document.getElementById("new_password").value;
    const confirm_new_password = document.getElementById("confirm_new_password")
      .value;

    if (
      current_password === "" ||
      new_password === "" ||
      confirm_new_password === ""
    ) {
      this.showAlert(
        e,
        alertSeverity.info,
        "All password fields are required."
      );
      return;
    }

    if (new_password !== confirm_new_password) {
      this.showAlert(
        e,
        alertSeverity.error,
        "New password fields do not match."
      );
      return;
    }

    try {
      let result = await Auth.changePassword(
        this.props.authProps.user,
        current_password,
        new_password
      );
      if (result.toLowerCase() === "success") {
        this.showAlert(
          e,
          alertSeverity.success,
          "Password successfully changed."
        );
      }
    } catch (e) {
      switch (e.name) {
        case "InvalidParameterException":
          this.showAlert(
            e,
            alertSeverity.error,
            "Passwords must be a minimum of 6 characters"
          );
          break;
        case "NotAuthorizedException":
          this.showAlert(
            e,
            alertSeverity.error,
            "Current password is incorrect"
          );
          break;
        default:
          this.showAlert(e, alertSeverity.error);
      }
    }
  };

  // PROFILE METHODS
  handleProfileChange = e => {
    if (this.state.editStatus.show) {
      this.hideAlert();
    }
    this.setState({ [e.target.id]: e.target.value });
  };

  updateProfile = async e => {
    e.preventDefault();

    const user = this.props.authProps.user;
    const newAttributes = {
      name: DOMPurify.sanitize(this.state.name),
      family_name: DOMPurify.sanitize(this.state.family_name),
      phone_number: DOMPurify.sanitize(this.state.phone_number),
      "address": DOMPurify.sanitize(this.state.address),
      "custom:address_line_2": DOMPurify.sanitize(
        this.state.custom_address_line_2
      ),
      "custom:city": DOMPurify.sanitize(this.state.custom_city),
      "custom:state": DOMPurify.sanitize(this.state.custom_state),
      "custom:zipcode": DOMPurify.sanitize(this.state.custom_zipcode)
    };

    try {
      await Auth.updateUserAttributes(user, newAttributes);
      user.storage.removeItem(user.userDataKey); // Force update Authenticator user data
      this.showAlert(e, alertSeverity.success, "Profile updated.");
    } catch (e) {
      this.showAlert(e, alertSeverity.error);
    }
  };

  render() {
    const { isAuthenticated, authState, user } = this.props.authProps;

    if (authState) {
      if (isAuthenticated && user) {
        return (
          <div>
            <h2>Edit Profile</h2>
            <form onSubmit={this.updatePassword}>
              <Box mb={4}>
                <p>
                  {user.attributes ? user.attributes.email : this.state.email}
                </p>
                <FormGroup>
                  <TextField
                    id="current_password"
                    type="password"
                    label="Current Password"
                    required
                  />
                  <TextField
                    id="new_password"
                    type="password"
                    label="New Password"
                    required
                  />
                  <TextField
                    id="confirm_new_password"
                    type="password"
                    label="Confirm New Password"
                    required
                  />
                  <Box my={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={this.updatePassword}
                    >
                      Update Password
                    </Button>
                  </Box>
                </FormGroup>
              </Box>
            </form>

            <form onSubmit={this.updateProfile}>
              <Box mb={4}>
                <FormGroup>
                  <TextField
                    id="name"
                    type="text"
                    label="First Name"
                    value={this.state.name}
                    onChange={this.handleProfileChange}
                  />
                  <TextField
                    id="family_name"
                    type="text"
                    label="Last Name"
                    value={this.state.family_name}
                    onChange={this.handleProfileChange}
                  />
                  <TextField
                    id="phone_number"
                    type="tel"
                    label="Phone"
                    value={this.state.phone_number}
                    onChange={this.handleProfileChange}
                    helperText="Phone number must begin with plus sign (+)"
                  />
                  <TextField
                    id="address"
                    type="text"
                    label="Street Address"
                    value={this.state.address}
                    onChange={this.handleProfileChange}
                  />
                  <TextField
                    id="custom_address_line_2"
                    type="text"
                    label="Address Line 2"
                    value={this.state.custom_address_line_2}
                    onChange={this.handleProfileChange}
                  />
                  <TextField
                    id="custom_city"
                    type="text"
                    label="City"
                    value={this.state.custom_city}
                    onChange={this.handleProfileChange}
                  />
                  <TextField
                    id="custom_state"
                    type="text"
                    label="State"
                    value={this.state.custom_state}
                    onChange={this.handleProfileChange}
                  />
                  <TextField
                    id="custom_zipcode"
                    type="text"
                    label="Zip Code"
                    inputProps={{ maxLength: 10 }}
                    value={this.state.custom_zipcode}
                    onChange={this.handleProfileChange}
                  />
                  <Box my={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={this.updateProfile}
                    >
                      Update Profile
                    </Button>
                  </Box>
                </FormGroup>
              </Box>
            </form>

            <Snackbar
              open={this.state.editStatus.show}
              autoHideDuration={3000}
              onClose={this.hideAlert}
            >
              <Alert
                onClose={this.hideAlert}
                severity={this.state.editStatus.type}
              >
                {this.state.editStatus.message}
              </Alert>
            </Snackbar>
          </div>
        );
      } else {
        return <Redirect to="/sign-in" />;
      }
    } else {
      return <span className="tag is-primary">Loading ...</span>;
    }
  }
}

export default UserAccount;

import { createMuiTheme } from "@material-ui/core";

const muiTheme = createMuiTheme({
  spacing: 10,
  palette: {
    primary: {
      main: "#74508E"
    },
    secondary: {
      main: "#00AEEF"
    }
  }
});

export default muiTheme;

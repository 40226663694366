import React from "react";

import { signUpConfig } from '../configs/signUpConfig';

import {
  Authenticator,
  ConfirmSignIn,
  SignUp,
  SignIn,
  //ConfirmSignUp,
  VerifyContact,
  ForgotPassword,
  Loading
} from "aws-amplify-react";
import CustomConfirmSignUp from './auth/CustomConfirmSignUp';
import PrettyFluidTheme from '../theme/PrettyFluid';
import { Link, NavLink } from "react-router-dom";

import { Auth } from 'aws-amplify';

// Material
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "#fff",
    },
    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    menuItem: {
      textDecoration: "none", 
      color: "black",
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      height: "80px",
      marginTop: "5px",
      marginBottom: "5px",
      width: "189px",
    },
  }),
);


const Navbar = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const authenticated = props.authState === "signedIn" && props.authData;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    Auth.signOut()
  };

  const menuLink = () => {
    if (authenticated) {
      return "/dashboard";
    } else {
      return "/";
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          
          <Link to={menuLink}>
            <img src="/logo.png" alt="logo" className={classes.logo} />
          </Link>

          <div className={classes.grow} />

          { authenticated &&
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="primary"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <NavLink to="/edit-profile" className={classes.menuItem} activeStyle={{ fontWeight: "550" }}>My account</NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose} >
                  <NavLink to="/dashboard" className={classes.menuItem} activeStyle={{ fontWeight: "550" }}>Dashboard</NavLink>
                </MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </div>
          }
        </Toolbar>
      </AppBar>
    </div>
  );
};

class AuthWrappedNavbar extends React.Component {
  /**
   * On state change, <Authenticator/> re-renders <Navbar/> and
   * then triggers an <App/> state change via updateAuthProps,
   * which triggers an <App/> re-render.
   * There's no need to re-render <Navbar/> in this case.
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state !== nextState ||
      JSON.stringify(this.props) !== JSON.stringify(nextProps)
    ) {
      return true;
    }
    return false;
  }

  render() {
    const navbarProps = this.props;
    const authenticatorProps = {
      hideDefault: true,
      onStateChange: navbarProps.updateAuthProps,
      usernameAttributes: "email",
      theme: PrettyFluidTheme,
    };

    if (navbarProps.location.pathname === "/sign-in") {
      return (
        <Authenticator {...authenticatorProps}>
          <Navbar {...navbarProps} />
          <ConfirmSignIn />
          {/*<ConfirmSignUp />*/}
          <CustomConfirmSignUp />
          <Loading />

          <SignIn />
          <SignUp signUpConfig={signUpConfig} />
          <VerifyContact />
          <ForgotPassword />
        </Authenticator>
      );
    } else {
      return (
        <Authenticator {...authenticatorProps}>
          <Navbar {...navbarProps} />
        </Authenticator>
      );
    }
  }
}

export default AuthWrappedNavbar;

export const signUpConfig = {
  header: 'Sign Up',
  hiddenDefaults: ['username', 'email'],
  defaultCountryCode: '1',
  usernameAttributes: 'email',
  signUpFields: [
    {
      label: 'First Name',
      key: 'name',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Last Name',
      key: 'family_name',
      required: true,
      displayOrder: 2,
      type: 'string'
    },
    {
      label: 'Email',
      //key: 'email',
      key: 'username',
      required: true,
      displayOrder: 3,
      type: 'email'
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 4,
      type: 'password'
    },
    {
      label: 'Address Line 1',
      key: 'address',
      required: true,
      displayOrder: 5,
      type: 'string'
    },
    {
      label: 'Address Line 2',
      key: 'custom:address_line_2',
      required: false,
      displayOrder: 6,
      type: 'string'
    },
    {
      label: 'Zip code',
      key: 'custom:zipcode',
      required: true,
      displayOrder: 7,
      type: 'string'
    },
    {
      label: 'City',
      key: 'custom:city',
      required: true,
      displayOrder: 8,
      type: 'string'
    },
    {
      label: 'State',
      key: 'custom:state',
      required: true,
      displayOrder: 9,
      type: 'string'
    },
    {
      label: 'Phone number',
      key: 'phone_number',
      required: true,
      displayOrder: 10,
      type: 'string',
    },
  ]
};

let config;

switch (process.env.REACT_APP_DEPLOY_ENV) {
  case "development":
    config = {
      Auth: {
        region: "us-east-1",
        userPoolId: "us-east-1_LcLdMpBeD",
        userPoolWebClientId: "5f2ap57p2pr5odgcr558d1e18k",
        mandatorySignIn: false,
      },
      API: {
        endpoints: [
          {
            name: "configs",
            endpoint: 'https://rija2lheqk.execute-api.us-east-1.amazonaws.com/dev/api/v1/configs',
            region: "us-east-1",
            custom_header: async () => {
              return { Authorization : 'rY5sTOvggPOwuZbh' }
            }
          },
          {
            name: "optOutStatus",
            endpoint: 'https://rija2lheqk.execute-api.us-east-1.amazonaws.com/dev/api/v1/configs/optout-status/',
            region: "us-east-1",
            custom_header: async () => {
              return { Authorization : 'rY5sTOvggPOwuZbh' }
            }
          }
        ]
      }
    };
    break;
  case "production":
    config = {
      Auth: {
        region: "us-east-1",
        userPoolId: "us-east-1_BMIYvq3pO",
        userPoolWebClientId: "2qjm3isnpr7p5lptkkukeadr3",
        mandatorySignIn: false,
      },
      API: {
        endpoints: [
          {
            name: "configs",
            endpoint: 'https://awq9q4fw8f.execute-api.us-east-1.amazonaws.com/prod/api/v1/configs',
            region: "us-east-1",
            custom_header: async () => {
              return { Authorization : 'cu56sX42tCzJVhIN' }
            }
          },
          {
            name: "optOutStatus",
            endpoint: 'https://awq9q4fw8f.execute-api.us-east-1.amazonaws.com/prod/api/v1/configs/optout-status/',
            region: "us-east-1",
            custom_header: async () => {
              return { Authorization : 'cu56sX42tCzJVhIN' }
            }
          }
        ]
      }
    };
    break;
  default:
    config = {
      Auth: {
        region: "us-east-1",
        userPoolId: "us-east-1_LcLdMpBeD",
        userPoolWebClientId: "5f2ap57p2pr5odgcr558d1e18k",
        mandatorySignIn: false,
      },
      API: {
        endpoints: [
          {
            name: "configs",
            endpoint: 'https://rija2lheqk.execute-api.us-east-1.amazonaws.com/dev/api/v1/configs',
            region: "us-east-1",
            custom_header: async () => {
              return { Authorization : 'rY5sTOvggPOwuZbh' }
            }
          },
          {
            name: "optOutStatus",
            endpoint: 'https://rija2lheqk.execute-api.us-east-1.amazonaws.com/dev/api/v1/configs/optout-status/',
            region: "us-east-1",
            custom_header: async () => {
              return { Authorization : 'rY5sTOvggPOwuZbh' }
            }
          }
        ]
      }
    };
    break;
};

export default config;

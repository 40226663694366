import React from "react";

class Dashboard extends React.Component {

  render() {
    console.log('dashboard', this.props);
    if (this.props.authProps.authState) {
      if (this.props.authProps.isAuthenticated && this.props.authProps.user) {
        return (
          <div className="dashboard">
            <h2 className="dashboard">Dashboard</h2>
            <h2 className="notifications">Notifications</h2>
          </div>
        );
      }
    } else {
      return <span className="tag is-primary">Loading ...</span>;
    }
  }
}

export default Dashboard;

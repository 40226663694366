export const themeColorPurple = '#74508E';

export const A = {
  color: themeColorPurple,
};

export const Button = {
  backgroundColor: themeColorPurple,
  color: '#fff'
};

export const NavBar = {
  backgroundImage: `url("logo.png")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto 80%",
  backgroundPosition: "5px center",
};

/**
 * The Actual Theme
 */
const PrettyFluidTheme = {
  navBar: NavBar,

  button: Button,
  navButton: Button,
  a: A,
};

export default PrettyFluidTheme;

import React from "react";
import { Redirect } from "react-router-dom";

/**
 * Fake page that redirects signed-in users back to account.
 * Sign in form handled by Authenticator
 */
const SignInPage = props => {
  if (props.authProps.isAuthenticated && props.authProps.user) {
    return <Redirect to="/dashboard" />;
  } else {
    return null;
  }
};

export default SignInPage;

import React from "react";
import { API } from "aws-amplify";

import { withStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Box } from "@material-ui/core";

const styles = theme => ({
  root: {
  },
  formControl: {
    margin: theme.spacing(3),
  },
  title: {
    color: "black",
    marginBottom: "5px",
    paddingBottom: "5px"
  },
  legend: {
    color: "black",
    marginBottom: "5px",
    paddingBottom: "5px",
    fontWeight: 600,
  },
  consumerField: {
    '& label.Mui-disabled': {
    },
    '& input': {
      color: "black",
    }
  }
});

class OptOutStatusPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      config: null,
      errorMsg: "",
      completed: false,
    };
  }

  async componentDidMount() {
    const configId = this.props.match.params.configId;

    try {
      const config = await API.get("configs", `/optout-status/${configId}`, {});
      this.setState({ config: config, errorMsg: "", loading: false });
    } catch (e) {
      this.setState({ config: null, errorMsg: "Could not find status form for given consumer.", loading: false });
    }
  }

  toggleMoreInfo (evt) {
    if (evt.target.checked === true) {
      document.getElementById('moreInfoContainer').style.display = 'block';
    } else {
      document.getElementById('moreInfoContainer').style.display = 'none';
    }
  }

  handleInputChange = (event) => {
    const name = event.target.name;
    let value;

    if (name === "consumer_data_more_info") {
      value = event.target.value;
    } else {
      value = event.target.checked === true ? 'true' : false;
    }
    this.setState({
      [name]: value
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submitting form.', this.state);
    try {
      await API.post("optOutStatus", `${ this.state.config.id }/`, { body: this.state });
      this.setState({ completed: true, loading: false, errorMsg: "" });
    } catch (e) {
      this.setState({ errorMsg: "Error submitting form.", loading: false });
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.loading === true) {
      return <h2>Loading....</h2>;
    } else if (this.state.loading === false && this.state.errorMsg) {
      return (
        <Box m={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {this.state.errorMsg}
          </Alert>
        </Box>
      );
    } else if (this.state.loading === false && !this.state.errorMsg && this.state.completed === true) {
      return (
        <Box m={2}>
          <Alert severity="success">
            <AlertTitle>Thank you</AlertTitle>
            The consumer status has been submitted
          </Alert>
        </Box>
      );
    } else {
      const consumer = this.state.config.consumer;
      const broker = this.state.config.broker;

      return (
        <div className={classes.root}>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <h2 className={classes.title}>Consumer Optout</h2>
            <Box mb={4}>
              <FormGroup>
                <TextField className={classes.consumerField} variant="filled" disabled label="Consumer Name" value={consumer.name} />
                <br />
                <TextField className={classes.consumerField} variant="filled" disabled label="Broker Name" value={broker.name} />
              </FormGroup>
            </Box>

            <Box mb={2}>
              <FormGroup className="brokerGroup">
                <FormLabel component="legend" className={classes.legend}>
                  Status
                </FormLabel>
                <FormControlLabel
                  label="Consumer Data Deleted"
                  control={<Checkbox color="primary" name="consumer_data_deleted" onChange={this.handleInputChange} />}
                />
                <FormControlLabel
                  label="Consumer Data Opted Out"
                  control={<Checkbox color="primary" name="consumer_data_opted_out" onChange={this.handleInputChange} />}
                />
                <FormControlLabel
                  label="Consumer Data Not Found"
                  control={<Checkbox color="primary" name="consumer_data_not_found" onChange={this.handleInputChange} />}
                />
                <FormControlLabel
                  label="More Info Needed / Comments"
                  control={
                    <Checkbox color="primary" 
                              name="consumer_data_more_info_needed"
                              value="consumer_data_more_info_needed" onChange={this.toggleMoreInfo} />}
                />
                <Box m={2} id="moreInfoContainer" display="none">
                  <TextField name="consumer_data_more_info" fullWidth label="Request additional info / comments ..." multiline rows={8} variant="outlined" onChange={this.handleInputChange} />
                </Box>
              </FormGroup>
            </Box>

            <Box mb={4}>
              <Button variant="contained" color="primary" type="submit">
                Submit 
              </Button>
            </Box>
          </form>
        </div>
      );
    }
  }
}

export default withStyles(styles)(OptOutStatusPage);

import React from "react";
import { Card, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Link
} from "react-router-dom";

const styles = theme => ({
  root: {
    minWidth: 275,
    marginTop: 50,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 24,
  },
  link: {
    color: "#74508E",
    textDecoration: "none",
  },
  pos: {
    marginBottom: 12,
  },
});

class SignUpConfirmed extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root}>
        <CardContent>
          <h3 className={classes.title}>Account Confirmed</h3>
          <p>Thank you for confirming your account.</p>
          <p>
            Go to <Link className={classes.link} to="/sign-in">Sign-In</Link>
          </p>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(SignUpConfirmed)

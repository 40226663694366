import React from 'react';

// Amplify
import SignUpConfirmed from './components/auth/SignUpConfirmed';

// Routes
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import SignInPage from "./components/containers/SignInPage";
import Dashboard from './components/containers/DashboardPage';
import OptOutStatusPage from './components/containers/OptOutStatusPage';

// Components
import NavBar from "./components/NavBar";
import UserAccount from "./components/UserAccount";

// Styles
import './App.css';
import { ThemeProvider } from "@material-ui/core/styles";
import muiTheme from "./theme/MuiTheme";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';


class App extends React.PureComponent {
  state = {
    isAuthenticated: false,
    user: null,
    authState: null
  };

  constructor(props) {
    super(props);
    this.updateAuthProps = this.updateAuthProps.bind(this);
  }

  updateAuthProps(authState, authData) {
    this.setState({
      user: authData,
      isAuthenticated: authState === "signedIn",
      authState
    });
  }

  render() {
    // to accommodate s3 static hosting:
    // https://viastudio.com/hosting-a-reactjs-app-with-routing-on-aws-s3/
    const history = createBrowserHistory();
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (path) {
      history.replace(path);
    }

    return (
      <div className="App">
        <CssBaseline />
        <ThemeProvider theme={muiTheme}>
          <BrowserRouter>
            <Route
              render={props => (
                <NavBar
                  {...props}
                  updateAuthProps={this.updateAuthProps}
                  signInPath={"/sign-in"}
                />
              )}
            />
            <Container maxWidth="lg">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/sign-in" />
                </Route>
                <Route
                  exact
                  path="/dashboard"
                  render={props => (
                    <Dashboard {...props} authProps={this.state} />
                  )}
                  />
                <Route
                  exact
                  path="/sign-in"
                  render={props => (
                    <SignInPage {...props} authProps={this.state} />
                  )}
                />
                <Route
                  exact
                  path="/account-confirmed/"
                  render={props => (
                    <SignUpConfirmed {...props} authProps={this.state} />
                  )}
                />
                <Route
                  exact
                  path="/edit-profile"
                  render={props => (
                    <UserAccount {...props} authProps={this.state} />
                  )}
                />
                <Route
                  exact
                  path="/optout-status/:configId"
                  render={props => (
                    <OptOutStatusPage {...props} authProps={this.state} />
                  )}
                />
              </Switch>
            </Container>
          </BrowserRouter>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
